import { navigate } from "gatsby"
import Modal from "./modal"

const REST = async ({ url, method, body, media }) => {
  try {
    const apiResponse = await fetch(url, {
      method: method || "GET",
      mode: "cors",
      cache: "no-cache",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })

    const jsonRes = await apiResponse.json()

    if (
      apiResponse.status === 400 &&
      jsonRes.message !==
        "Application cannot be updated.Application status is:CANCELED. Cancel the application and create a new one if you want to change information!" &&
      jsonRes.message !==
        "Application cannot be updated.Application status is:REJECTED. Cancel the application and create a new one if you want to change information!"
    ) {
      Modal.mixin({
        customClass: {
          confirmButton: "btn btn-dark btn-xl fw-600 w-40",
          cancelButton: "btn btn-danger btn-xl fw-600 w-40",
        },
        buttonsStyling: false,
        backdrop: "#f1f1f1",
      })
        .fire({
          title: "<strong style='text-transform: uppercase'>Hmm...</strong>",
          text:
            jsonRes.message === "Loan application could not be found!"
              ? "Invitation link not found or expired."
              : jsonRes.message ===
                "Please, enter your own mobile phone number."
              ? jsonRes.errors[0].message
              : jsonRes.message === "You should be owner of the bank account."
              ? "Unfortunately, we cannot accept this bank account. Please, enter a different bank account on which you are an account holder (i.e. your name appears on the bank statement)"
              : jsonRes.message ===
                "There is another account using the same email address."
              ? jsonRes.errors[0].message
              : "Something went wrong. Please try again later.",
          confirmButtonText: "Ok",
        })
        .then(result => {
          if (result.isConfirmed) {
            document.body.scrollTop = 0
            document.documentElement.scrollTop = 0
            if (
              jsonRes.message !==
                "Please, enter your own mobile phone number." &&
              jsonRes.message !== "You should be owner of the bank account." &&
              jsonRes.message !==
                "There is another account using the same email address."
            ) {
              navigate("/")
            }
          }
        })
    }

    if (apiResponse.status === 401 || apiResponse.status === 403) {
      Modal.mixin({
        customClass: {
          confirmButton: "btn btn-dark btn-xl fw-600 w-40",
          cancelButton: "btn btn-danger btn-xl fw-600 w-40",
        },
        buttonsStyling: false,
      })
        .fire({
          title: "<strong style='text-transform: uppercase'>Hmm...</strong>",
          text:
            jsonRes.message === "Account not found!"
              ? "We couldn’t find your Trustic account. Please, login with your email or reset your password."
              : jsonRes.message === "Wrong credentials!"
              ? "It seems we cannot find your account. For assistance, please, email to support@trustic.com."
              : jsonRes.message === "Login required!"
              ? "It seems you already have an account with Trustic. Please, login to continue your loan application. If you don’t remember your password, please check your email for your Trustic account details."
              : "You’ve been logged out due to inactivity. Please, login to continue your application. If you don’t remember your password, please check your email for your Trustic account details.",
          confirmButtonText: "Log in",
        })
        .then(result => {
          // if (result.isConfirmed) {
          navigate("/apply/login")
          // }
        })
    }

    return [jsonRes, apiResponse.status]
  } catch (error) {
    console.log(error)
  }
}

export { REST }
